<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCardBody>
          <CDataTable
                  :items="apiDatas"
                  :fields="fields"
                  column-filter
                  table-filter
                  items-per-page-select
                  :items-per-page="5"
                  sorter
                  pagination
                  clickable-rows
                  hover
          >
            <!--<template #actions="{ item }">
              <td>
                <CButton @click="removeRegion(item)" color="primary">Удалить</CButton>
              </td>
            </template>-->
          </CDataTable>
        </CCardBody>
      </CCol>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Добавить область</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="createRegion()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                description="Введите название области"
                label="Введите название области"
                v-model="form.region_name"
                required
              />
              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Добавить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
    import axios from 'axios'

    const fields = [
        {
            key: "name",
            label: "Название области",
            _style: "min-width:100%;"
        },
        // {
        //     key: "actions",
        //     label: "Действия",
        //     _style: "min-width:1%;",
        //     sorter: false,
        //     filter: false
        // },
    ];

    export default {
        name: 'Regions',
        data: function() {
            return {
                apiDatas: [],
                tableFields: ['id', 'name'],
                form: {
                    action: 'add_region',
                    region_name: ''
                },
                fields,
            }
        },
        methods: {
            getDataFromApi () {
                axios
                    .get(`${axios.defaults.baseURL}?action=get_regions`)
                    .then(response => (this.apiDatas = response.data))
            },
            createRegion () {

                let bodyFormData = new FormData();

                bodyFormData.append('action', this.form.action);
                bodyFormData.append('region_name', this.form.region_name);

                // console.log(bodyFormData)

                axios({
                      method: "post",
                      url: `${axios.defaults.baseURL}`,
                      data: bodyFormData
                    })
                    .then((res) => {
                        console.log(res)
                        //Perform Success Action
                        this.getDataFromApi()
                    })
                    .catch((error) => {
                        console.error(error)
                        // error.response.status Check status code
                    }).finally(() => {
                      // this.getDataFromApi()
                    });
            },
            removeRegion (item) {

                let bodyFormData = new FormData();

                bodyFormData.append('action', "remove_region");
                bodyFormData.append('region_id', item.id);

                axios({
                    method: "post",
                    url: `${axios.defaults.baseURL}`,
                    data: bodyFormData
                })
                    .then(() => {
                        //Perform Success Action
                        this.getDataFromApi()
                    })
                    .catch((error) => {
                        console.error(error)
                        // error.response.status Check status code
                    }).finally(() => {
                    // this.getDataFromApi()
                });
            },
        },
        mounted() {
            axios
                .get(`${axios.defaults.baseURL}?action=get_regions`)
                .then(response => (this.apiDatas = response.data))
        },
    }
</script>
